<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col
        xs="12"
        md="4"
      >
        <customer-card
          v-if="id_com_customer"
          :id_com_customer="id_com_customer"
        />
      </b-col>
      <b-col
        xs="12"
        md="8"
      >
        <b-card title="Hatırlatma">
          <alert-date />
          <alert-notes />
          <save-button :action-methods="submitForm" />
        </b-card>
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import CustomerCard from '@/views/Alerts/components/CustomerCard'
// eslint-disable-next-line import/extensions
import AlertDate from '@/views/Alerts/forms/AlertDate'
// eslint-disable-next-line import/extensions
import AlertNotes from '@/views/Alerts/forms/AlertNotes'
// eslint-disable-next-line import/extensions
import SaveButton from '@/views/Alerts/forms/SaveButton'

export default {
  name: 'Add',
  components: {
    ValidationObserver,
    BRow,
    BCol,
    AlertDate,
    AlertNotes,
    SaveButton,
    BCard,
    CustomerCard,
  },
  data() {
    return {
      locale: 'tr',
      submitStatus: false,
      id_com_customer: this.$route.params.id,
    }
  },
  computed: {
    alert() {
      return this.$store.getters['alerts/getAlert']
    },
    saveStatus() {
      return this.$store.getters['alerts/getAlertSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.$refs.simpleRules.reset()
        this.$store.dispatch('alerts/alertReset')
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.alert.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.alert.id_com_customer = this.$route.params.id
          this.submitStatus = true
          this.alert.submitStatus = true
          this.$store.dispatch('alerts/alertSave', this.alert)
        }
      })
    },
  },
}
</script>
