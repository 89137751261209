<template>
  <b-button
    variant="danger"
    :disabled="alert.submitStatus"
    @click="saveAction"
  >
    Kaydet
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'SaveButton',
  components: {
    BButton,
  },
  props: {
    actionMethods: {
      type: Function,
      required: true,
    },
  },
  computed: {
    alert() {
      return this.$store.getters['alerts/getAlert']
    },
  },
  methods: {
    saveAction() {
      this.actionMethods()
    },
  },
}
</script>
